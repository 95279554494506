import React, { createContext, useContext, useEffect, useCallback, useState, useMemo } from 'react';
import { usePrivySmartAccount } from "@zerodev/privy";
import { createPublicClient, http } from "viem";
import { base } from 'viem/chains';

const QUICKNODE_RPC = "https://few-holy-cloud.base-mainnet.quiknode.pro/540aed7fe1e954f46b2f8c28e723a35fa0c83b98";

export const AuthContext = createContext();

const log = (message, data = null) => {
  console.log(`AuthProvider: ${message}`, data);
};

export const AuthProvider = ({ children }) => {
  const [publicClient, setPublicClient] = useState(null);

  useEffect(() => {
    const client = createPublicClient({
      transport: http(QUICKNODE_RPC),
      chain: base,
    });
    setPublicClient(client);
  }, []);

  const privySmartAccountConfig = useMemo(() => ({
    publicClient
  }), [publicClient]);

  const privySmartAccount = usePrivySmartAccount(privySmartAccountConfig);
  
  const {
    ready,
    authenticated,
    login,
    logout,
    sendTransaction,
    user
  } = privySmartAccount;

  const [smartWalletAddress, setSmartWalletAddress] = useState(null);

  useEffect(() => {
    if (authenticated && user?.wallet?.address) {
      setSmartWalletAddress(user.wallet.address);
    } else {
      setSmartWalletAddress(null);
    }
  }, [authenticated, user?.wallet?.address]);

  const getSmartWalletAddress = useCallback(() => smartWalletAddress, [smartWalletAddress]);

  const value = useMemo(() => ({
    ready,
    authenticated,
    login,
    logout,
    sendTransaction,
    getSmartWalletAddress,
    user,
    smartWalletAddress,
    publicClient
  }), [ready, authenticated, login, logout, sendTransaction, getSmartWalletAddress, user, smartWalletAddress, publicClient]);

  useEffect(() => {
    log('Auth state changed', {
      ready,
      authenticated,
      userAddress: user?.wallet?.address,
      smartWalletAddress
    });
  }, [ready, authenticated, user?.wallet?.address, smartWalletAddress]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};